import type { SetFieldError } from "~/types/forms";

export type SaleorError<T extends string = string, Code extends string = string> = {
  __typename?: T;
  code: Code;
  message?: string | null;
  field?: string | null | undefined;
};

export const useSaleorError = () => {
  const { te, t } = useI18n();

  const tse = <Error extends SaleorError>(e: Error): string => {
    const baseKey = `saleor.errors.${e.__typename}.${e.code}`;
    const baseKeyWithField = `${baseKey}.${e.field ?? "text"}`;

    if (te(baseKeyWithField)) {
      return t(baseKeyWithField);
    }

    if (te(baseKey)) {
      return t(baseKey);
    }

    const commonKey = `saleor.errors.common.${e.code}`;
    if (te(commonKey)) {
      return t(commonKey);
    }

    return e.message ?? t(baseKey);
  };

  return { tse };
};

export const useSetFieldErrors = (_setFieldError?: SetFieldError) => {
  const { tse } = useSaleorError();

  const setFieldErrors = <
    TError extends SaleorError & {
      field?: string | null;
    },
  >(
    errors?: TError[],
    fieldPrefix = "",
    setFieldError: SetFieldError | undefined = _setFieldError
  ) => {
    if (!errors?.length) return;

    errors
      .filter((e) => e.field)
      .forEach((error) => {
        // FIXME:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFieldError?.(`${fieldPrefix}${error.field}` as any, tse(error));
      });
  };

  return { setFieldErrors };
};
